@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.fully-justify {
  text-align: justify;
  margin-bottom: -1em !important;
}
.fully-justify:after {
  content: "";
  display: inline-block;
  width: 100%;
}

html {
  scroll-behavior: smooth;
}

p,
span {
  @apply font-display;
}
